export const MENU_HOME          = 1;
export const MENU_AGENCY        = 2;
export const MENU_OPERATORS     = 3;
export const MENU_CONVEYORS     = 4;
export const MENU_UNITS         = 5;
export const MENU_SERVICES      = 6;
export const MENU_RESERVATIONS  = 7;
export const MENU_PROMOTIONS    = 8;
export const MENU_CASH_PAYMENT  = 9;
export const MENU_BANKS         = 10;
export const MENU_USERS         = 11;
export const MENU_PROFILES      = 12;
export const MENU_PENDING_PAYMENTS= 13;
export const MENU_QUOTE = 14;