<template>
    <div v-if="isLoading" class="body-loader">
        <h1 class="loader" v-if="letters">
            <span v-for="(letter, index) in letters" :key="index"> {{ letter }} </span>
        </h1>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "LoadingPage",
    data(){
        return {
            config  : "",
            letters : "",
        }
    },
    mounted() {
        setTimeout(() => {
            const config = JSON.parse(localStorage.getItem('configuration'));
            if (config !== undefined && config !== null) {
                this.config     = config;
                this.letters    = config.name.split('');
            }
        }, 500);
    },
    computed: {
        ...mapState(['isLoading'])
    },
}
</script>

<style lang="css">
    .body-loader{
        background: #2999e1;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2000;
    }
    h1.loader {
        text-align: center;
        text-transform: uppercase;
        font-family: 'Nunito', sans-serif;
        font-size: 4.6875em;
        color: transparent;
        letter-spacing: 0.01em;
        position: absolute;
        z-index: 2100;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .loader span {
        text-shadow:
        0 0 2px rgba(204, 208, 212,0.9),
        0 15px 25px rgba(0, 0, 0, 0.3),
        0 -2px 3px rgba(0, 0, 0, 0.1),
        0 -5px 10px rgba(0, 0, 0, 0.5),
        0 5px 10px rgba(0, 0, 0, 0.3),
        0 3px 4px rgba(0, 0, 0, 0.2),
        0 0 20px rgba(0, 0, 0, 0.45);
    
        animation: loading 0.85s ease-in-out infinite alternate;
    }

    @keyframes loading {
        to {text-shadow:
        0 0 2px rgba(204, 208, 212,0.2),
        0 0 3px rgba(0, 0, 0, 0.02),
        0 0 0 rgba(0, 0, 0, 0),
        0 0 0 rgba(255, 255, 255, 0),
        0 0 0 rgba(0, 0, 0, 0),
        0 0 0 rgba(255, 255, 255, 0),
        0 0 0 rgba(255, 255, 255, 0);}
    }

    .loader span:nth-child(2) {
        animation-delay:0.15s;
    }

    .loader span:nth-child(3) {
        animation-delay:0.30s;
    }

    .loader span:nth-child(4) {
        animation-delay:0.45s;
    }

    .loader span:nth-child(5) {
        animation-delay:0.60s;
    }

    .loader span:nth-child(6) {
        animation-delay:0.75s;
    }

    .loader span:nth-child(7) {
        animation-delay:0.90s;
    }
</style>