import Vue from 'vue'
import VueRouter from 'vue-router'

import { 
    ROUTE_LOGIN,
    ROUTE_HOME,
    ROUTE_AGENCIES,
    ROUTE_AGENCIESADD,
    ROUTE_AGENCIESEDIT,
    ROUTE_AGENCIES_COMMISSIONS,
    ROUTE_USER_ADMIN,
    ROUTE_USER_ADD,
    ROUTE_USER_EDIT,
    ROUTE_OPERATORS,
    ROUTE_OPERATOR_ADD,
    ROUTE_OPERATOR_EDIT,
    ROUTE_TRANSPORTS,
    ROUTE_TRANSPORT_ADD,
    ROUTE_TRANSPORT_EDIT,
    ROUTE_UNITS,
    ROUTE_SERVICE_HOTELS,
    ROUTE_SERVICE_CIRCUITS,
    ROUTE_SERVICE_DAYTRIPS,
    ROUTE_SERVICE_TOURS,
    ROUTE_SERVICE_INTERNATIONALS,
    ROUTE_SERVICE_LOCKEDS,
    ROUTE_SERVICE_ADD_LOCKED,
    ROUTE_SERVICE_ADD,
    ROUTE_SERVICE_EDIT,
    ROUTE_SERVICE_TRAVEL_DATE,
    ROUTE_SERVICE_EXIT_PLACES,
    ROUTE_SERVICE_EXIT_PLACE_PRICES,
    ROUTE_SERVICE_TRAVEL_UNIT,
    ROUTE_PROFILE_LIST,
    ROUTE_PROFILE_ADD,
    ROUTE_PROFILE_EDIT,
    ROUTE_BANKS,
    ROUTE_BANK_ADD,
    ROUTE_BANK_EDIT,
    ROUTE_PROMOTIONS,
    ROUTE_PROMOTION_ADD,
    ROUTE_PROMOTION_EDIT,
    ROUTE_RESERVATION_HOTEL_INDEX,
    ROUTE_RESERVATION_HOTEL_SHOW,
    ROUTE_RESERVATION_CIRCUIT_INDEX,
    ROUTE_RESERVATION_CIRCUIT_SHOW,
    ROUTE_RESERVATION_DAYTRIPS_INDEX,
    ROUTE_RESERVATION_DAYTRIPS_SHOW,
    ROUTE_RESERVATION_INTERNATIONALS_INDEX,
    ROUTE_RESERVATION_INTERNATIONALS_SHOW,
    ROUTE_RESERVATION_FLIGHTS_INDEX,
    ROUTE_RESERVATION_FLIGHTS_SHOW,
    ROUTE_RESERVATION_OTHER_SERVICE_INDEX,
    ROUTE_RESERVATION_OTHER_SERVICE_SHOW,
    ROUTE_RESERVATION_TOURS_INDEX,
    ROUTE_RESERVATION_TOURS_SHOW,
    ROUTE_RESERVATION_PAYMENT_TO_BROOKER,
    ROUTE_RESERVATION_PAYMENT_TO_BROOKER_VIEW,
    ROUTE_USER_NOTIFICATIONS,
    ROUTE_CASH_PAYMENT,
    ROUTE_PROMOTION_FOLDER,
    ROUTE_AGENCIES_EMAIL,
    ROUTE_AGENCIES_ACTIONS,
    ROUTE_BANK_HISTORY_MOVE_LIST,
    ROUTE_RESERVATION_PAYMENT_GLOBAL_TO_BROOKER,
    ROUTE_AGENCIES_BOXES,
    ROUTE_AGENCIES_BOXES_HISTORY,
    ROUTE_AGENCIES_BOXES_HISTORY_MOVE_LIST,
    ROUTE_AGENCIES_SAVINGS,
    ROUTE_RESERVATION_PASS_INDEX,
    ROUTE_PROFILE,
    ROUTE_CONFIGURATIONS,
    ROUTE_PENDING_PAYMENTS,
    ROUTE_RESERVATION_TRANSFER_INDEX,
    ROUTE_RESERVATION_TRANSFER_SHOW,
    ROUTE_RESERVATION_PACKAGED_INDEX,
    ROUTE_SERVICE_QUOTE,
    ROUTE_SERVICE_QUOTE_VIEW,
 } from "../enums/RouteNameEnums";

import { ACCESS_MENU } from "../validations/auth/AccessControlList";

Vue.use(VueRouter)

const guest = async (to, from, next) => {
    if (!localStorage.getItem("authToken")) {
        return next();
    } else {
        return next("/admin/dashboard");
    }
};

const auth = async (to, from, next) => {
    if (localStorage.getItem("authToken")) {
        if(ACCESS_MENU(to.name.split("_")[1])){
            return next();
        }else{
            return next({
                path: '/not-authorized'
            });
        }
    } else {
        return next({
            path: '/login'
        });
    }
};

const routes = [
    {
        path: "/",
        name: "AuthPage",
        beforeEnter: guest,
        component: () => import('../components/auth/Login.vue'),
        redirect: '/login',
        children:[
            {
                path: 'login',
                name: ROUTE_LOGIN,
                beforeEnter: guest,
                component: () => import('../components/auth/Login.vue')
            },
        ]
    },
    {
        path: '/not-authorized',
        name: 'NOT_AUTHORIZED',
        //beforeEnter: guest,
        component: () => import('../components/auth/NotAuthorized.vue')
    },
    { 
        path: '*', 
        name: 'NotFound',
        component: () => import('../components/home/NotFoundComponent.vue')
    },
    {
        path: '/admin',
        name: 'TemplateDashboard',
        component: () => import('../components/partials/TemplateDashboard.vue'),
        beforeEnter: auth,
        redirect: '/admin/dashboard',
        children:[
            {
                path        : 'dashboard',
                name        : ROUTE_HOME,
                beforeEnter : auth,
                component   : () => import('../components/home/Dashboard.vue')
            },
            {
                path        : 'agencias',
                name        : ROUTE_AGENCIES,
                beforeEnter : auth,
                component   : () => import('../components/agencies/AgencyList.vue')
            },
            {
                path        : 'agencia/agregar',
                name        : ROUTE_AGENCIESADD,
                beforeEnter : auth,
                component   : () => import('../components/agencies/AgencyAdd.vue'),
                props       : {'idAgency' : 0}
            },
            {
                path        : 'agencia/:idAgency/editar',
                name        : ROUTE_AGENCIESEDIT,
                beforeEnter : auth,
                component   : () => import('../components/agencies/AgencyAdd.vue'),
                props       : true
            },
            {
                path        : 'agencia/:idAgency/comisiones',
                name        : ROUTE_AGENCIES_COMMISSIONS,
                beforeEnter : auth,
                component   : () => import('../components/agencies/AgencyCommission.vue'),
                props       : true
            },
            {
                path        : 'agencia/:idAgency/correos',
                name        : ROUTE_AGENCIES_EMAIL,
                beforeEnter : auth,
                component   : () => import('../components/agencies/AgencyEmails.vue'),
                props       : true
            },
            {
                path        : 'agencia/:idAgency/acciones',
                name        : ROUTE_AGENCIES_ACTIONS,
                beforeEnter : auth,
                component   : () => import('../components/agencies/AgencyAction.vue'),
                props       : true
            },
            {
                path        : 'agencia/:idAgency/cajas',
                name        : ROUTE_AGENCIES_BOXES,
                beforeEnter : auth,
                component   : () => import('../components/agencies/agency_boxes/Index.vue'),
                props       : true
            },
            {
                path        : `agencia/:idAgency/caja/historial/:idBox`,
                name        : ROUTE_AGENCIES_BOXES_HISTORY,
                beforeEnter : auth,
                component   : () => import('../components/agencies/agency_boxes/CutHistory.vue'),
                props       : true
            },
            {
                path        : `agencia/:idAgency/caja/historial/:idBox/movimientos/:idBoxCut`,
                name        : ROUTE_AGENCIES_BOXES_HISTORY_MOVE_LIST,
                beforeEnter : auth,
                component   : () => import('../components/agencies/agency_boxes/BoxMoveList.vue'),
                props       : true
            },

            {
                path        : 'agencia/:idAgency/ahorros',
                name        : ROUTE_AGENCIES_SAVINGS,
                beforeEnter : auth,
                component   : () => import('../components/agencies/agency_savings/Index.vue'),
                props       : true
            },


            {
                path        : 'administradores',
                name        : ROUTE_USER_ADMIN,
                beforeEnter : auth,
                component   : () => import('../components/users/ListAdministrators.vue'),
            },
            {
                path        : `administradores/agregar`,
                name        : ROUTE_USER_ADD,
                beforeEnter : auth,
                component   : () => import('../components/users/AddUsers.vue'),
                props       : true
            },
            {
                path        : `administradores/:idUser/editar`,
                name        : ROUTE_USER_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/users/EditUser.vue'),
                props       : true
            },

            {
                path        : `administradores/:idUser/notificaciones`,
                name        : ROUTE_USER_NOTIFICATIONS,
                beforeEnter : auth,
                component   : () => import('../components/users/UserNotifications.vue'),
                props       : true
            },
            
            {
                path        : `operadoras`,
                name        : ROUTE_OPERATORS,
                beforeEnter : auth,
                component   : () => import('../components/operators/ListOperators.vue')
            },
            {
                path        : `operadoras/agregar`,
                name        : ROUTE_OPERATOR_ADD,
                beforeEnter : auth,
                component   : () => import('../components/operators/AddOperators.vue'),
                props       : { 'idOperator' : 0 }
            },
            {
                path        : `operadoras/:idOperator/editar`,
                name        : ROUTE_OPERATOR_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/operators/AddOperators.vue'),
                props       : true
            },
            {
                path        : `transportadoras`,
                name        : ROUTE_TRANSPORTS,
                beforeEnter : auth,
                component   : () => import('../components/conveyors/ListConveyors.vue')
            },
            {
                path        : `transportadoras/agregar`,
                name        : ROUTE_TRANSPORT_ADD,
                beforeEnter : auth,
                component   : () => import('../components/conveyors/AddConveyors.vue'),
                props       : { 'idConveyor' : 0 }
            },
            {
                path        : `transportadoras/:idConveyor/editar`,
                name        : ROUTE_TRANSPORT_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/conveyors/AddConveyors.vue'),
                props       : true
            },
            {
                path        : `unidades`,
                name        : ROUTE_UNITS,
                beforeEnter : auth,
                component   : () => import('../components/units/ListUnits.vue'),
            },
            //SERVICES
            {
                path        : `servicios/hoteles`,
                name        : ROUTE_SERVICE_HOTELS,
                beforeEnter : auth,
                component   : () => import('../components/services/hotels/ListHotels.vue'),
            },
            {
                path        : `servicios/circuitos`,
                name        : ROUTE_SERVICE_CIRCUITS,
                beforeEnter : auth,
                component   : () => import('../components/services/circuits/ListCircuits.vue'),
            },
            {
                path        : `servicios/pasadias`,
                name        : ROUTE_SERVICE_DAYTRIPS,
                beforeEnter : auth,
                component   : () => import('../components/services/daytrips/ListDaytrips.vue'),
            },
            {
                path        : `servicios/tours`,
                name        : ROUTE_SERVICE_TOURS,
                beforeEnter : auth,
                component   : () => import('../components/services/tours/ListTours.vue'),
            },
            {
                path        : `servicios/internacionales`,
                name        : ROUTE_SERVICE_INTERNATIONALS,
                beforeEnter : auth,
                component   : () => import('../components/services/internationals/ListInternationals.vue'),
            },
            {
                path        : `servicios/bloqueos`,
                name        : ROUTE_SERVICE_LOCKEDS,
                beforeEnter : auth,
                component   : () => import('../components/services/lockeds/ListLockeds.vue'),
            },
            {
                path        : `servicios/bloqueos/agregar`,
                name        : ROUTE_SERVICE_ADD_LOCKED,
                beforeEnter : auth,
                component   : () => import('../components/services/lockeds/AddLocked.vue'),
            },
            {
                path        : `servicios/:service_type/agregar`,
                name        : ROUTE_SERVICE_ADD,
                beforeEnter : auth,
                component   : () => import('../components/services/AddService.vue'),
                props       : true
            },
            {
                path        : `servicios/:service_type/:idService/editar`,
                name        : ROUTE_SERVICE_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/services/EditService.vue'),
                props       : true
            },
            {
                path        : `servicios/:service_type/:idService/fechas-de-viaje`,
                name        : ROUTE_SERVICE_TRAVEL_DATE,
                beforeEnter : auth,
                component   : () => import('../components/services/travel-dates/TravelDates.vue'),
                props       : true
            },
            // {
            //     path        : `servicios/:idTravelDate/lugares-de-salida`,
            //     name        : ROUTE_SERVICE_EXIT_PLACES,
            //     beforeEnter : auth,
            //     component   : () => import('../components/services/travel-dates/ExitPlaces.vue'),
            //     props       : true
            // },
            {
                path        : `servicios/:idTravelDate/lugares-de-salida/:idExitPlace/precios`,
                name        : ROUTE_SERVICE_EXIT_PLACE_PRICES,
                beforeEnter : auth,
                component   : () => import('../components/services/travel-dates/ExitPlacePrices.vue'),
                props       : true
            },
            // {
            //     path        : `servicios/:idTravelDate/unidades-de-viaje`,
            //     name        : ROUTE_SERVICE_TRAVEL_UNIT,
            //     beforeEnter : auth,
            //     component   : () => import('../components/services/travel-dates/TravelDateUnit.vue'),
            //     props       : true
            // },


            /**COTIZADOR */
            {
                path        : `cotizador`,
                name        : ROUTE_SERVICE_QUOTE,
                beforeEnter : auth,
                component   : () => import('../components/quotes/Index.vue'),
                props       : true
            },
            {
                path        : `cotizador/:idQuote/editar`,
                name        : ROUTE_SERVICE_QUOTE_VIEW,
                beforeEnter : auth,
                component   : () => import('../components/quotes/Edit.vue'),
                props       : true
            },

            /**RESERVATIONS */

            /*  URL de reservaciones de HOTEL */
            {
                path        : `reservaciones/hoteles`,
                name        : ROUTE_RESERVATION_HOTEL_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/hotels/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/hoteles/:idReservation/ver`,
                name        : ROUTE_RESERVATION_HOTEL_SHOW,
                beforeEnter : auth,
                component   : () => import('../components/reservations/hotels/Show.vue'),
                props       : true
            },

            /*  URL de reservaciones de CIRCUIT */
            {
                path        : `reservaciones/circuitos`,
                name        : ROUTE_RESERVATION_CIRCUIT_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/circuits/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/circuitos/:idReservation/ver`,
                name        : ROUTE_RESERVATION_CIRCUIT_SHOW,
                beforeEnter : auth,
                component   : () => import('../components/reservations/circuits/Show.vue'),
                props       : true
            },

            /*  URL de reservaciones de DAYTRIPS */
            {
                path        : `reservaciones/pasadias`,
                name        : ROUTE_RESERVATION_DAYTRIPS_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/daytrips/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/pasadias/:idReservation/ver`,
                name        : ROUTE_RESERVATION_DAYTRIPS_SHOW,
                beforeEnter : auth,
                component   : () => import('../components/reservations/daytrips/Show.vue'),
                props       : true
            },

            /*  URL de reservaciones de TOURS */
            {
                path        : `reservaciones/tours`,
                name        : ROUTE_RESERVATION_TOURS_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/tours/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/tours/:idReservation/ver`,
                name        : ROUTE_RESERVATION_TOURS_SHOW,
                beforeEnter : auth,
                component   : () => import('../components/reservations/tours/Show.vue'),
                props       : true
            },

            /*  URL de reservaciones de INTERNATIONALS */
            {
                path        : `reservaciones/internacionales`,
                name        : ROUTE_RESERVATION_INTERNATIONALS_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/internationals/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/internacionales/:idReservation/ver`,
                name        : ROUTE_RESERVATION_INTERNATIONALS_SHOW,
                beforeEnter : auth,
                component   : () => import('../components/reservations/internationals/Show.vue'),
                props       : true
            },

            /*  URL de reservaciones de FLIGHTS */
            {
                path        : `reservaciones/vuelos`,
                name        : ROUTE_RESERVATION_FLIGHTS_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/flights/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/vuelos/:idReservation/ver`,
                name        : ROUTE_RESERVATION_FLIGHTS_SHOW,
                beforeEnter : auth,
                component   : () => import('../components/reservations/flights/Show.vue'),
                props       : true
            },

            /*  URL de reservaciones de OTHER SERVICES */
            {
                path        : `reservaciones/otros-servicios`,
                name        : ROUTE_RESERVATION_OTHER_SERVICE_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/other-services/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/otros-servicios/:idReservation/ver`,
                name        : ROUTE_RESERVATION_OTHER_SERVICE_SHOW,
                beforeEnter : auth,
                component   : () => import('../components/reservations/other-services/Show.vue'),
                props       : true
            },

            /*  URL de reservaciones de TRANSFERS */
            {
                path        : `reservaciones/traslados`,
                name        : ROUTE_RESERVATION_TRANSFER_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/transfers/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/traslados/:idReservation/ver`,
                name        : ROUTE_RESERVATION_TRANSFER_SHOW,
                beforeEnter : auth,
                component   : () => import('../components/reservations/transfers/Show.vue'),
                props       : true
            },

            /*  URL de reservaciones de Empaquetados */
            {
                path        : `reservaciones/empaquetados`,
                name        : ROUTE_RESERVATION_PACKAGED_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/packaged/Index.vue'),
                props       : true
            },

            /** Payments to brookers */
            {
                path        : `reservaciones/:idReservation/pago-a-brookers`,
                name        : ROUTE_RESERVATION_PAYMENT_TO_BROOKER,
                beforeEnter : auth,
                component   : () => import('../components/reservations/ReservationPaymentToBrooker.vue'),
                props       : true
            },
            {
                path        : `reservaciones/:idReservation/pago-a-brookers/:idPayment/ver`,
                name        : ROUTE_RESERVATION_PAYMENT_TO_BROOKER_VIEW,
                beforeEnter : auth,
                component   : () => import('../components/reservations/ReservationPaymentToBrookerView.vue'),
                props       : true
            },
            {
                path        : `reservaciones/pago-a-brookers/:idPayment/ver-multiple`,
                name        : ROUTE_RESERVATION_PAYMENT_TO_BROOKER_VIEW,
                beforeEnter : auth,
                component   : () => import('../components/reservations/ReservationPaymentToBrookerMultipleView.vue'),
                props       : true
            },
            {
                path        : `reservaciones/:idReservation/pago-global-a-brookers`,
                name        : ROUTE_RESERVATION_PAYMENT_GLOBAL_TO_BROOKER,
                beforeEnter : auth,
                component   : () => import('../components/reservations/ReservationPaymentGlobalToBrooker.vue'),
                props       : true
            },

            /**Reservation Pass */
            {
                path        : `reservaciones/:idReservation/pases`,
                name        : ROUTE_RESERVATION_PASS_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/ReservationPass.vue'),
                props       : true
            },

            //PROFILE
            {
                path        : `perfiles`,
                name        : ROUTE_PROFILE_LIST,
                beforeEnter : auth,
                component   : () => import('../components/profiles/ProfileList.vue'),
                props       : true
            },
            {
                path        : `perfiles/agregar`,
                name        : ROUTE_PROFILE_ADD,
                beforeEnter : auth,
                component   : () => import('../components/profiles/ProfileCreate.vue'),
                props       : true
            },
            {
                path        : `perfiles/:idProfile/editar`,
                name        : ROUTE_PROFILE_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/profiles/ProfileEdit.vue'),
                props       : true
            },

            //Cash Payments
            {
                path        : 'pagos-efectivo',
                name        : ROUTE_CASH_PAYMENT,
                beforeEnter : auth,
                component   : () => import('../components/payments/CashPayments.vue')
            },

            {
                path        : 'pagos-por-confirmar',
                name        : ROUTE_PENDING_PAYMENTS,
                beforeEnter : auth,
                component   : () => import('../components/payments/PendingPayments.vue')
            },

            //Banks
            {
                path        : 'cuentas',
                name        : ROUTE_BANKS,
                beforeEnter : auth,
                component   : () => import('../components/banks/BankList.vue')
            },
            {
                path        : 'cuentas/agregar',
                name        : ROUTE_BANK_ADD,
                beforeEnter : auth,
                component   : () => import('../components/banks/BankAdd.vue'),
                props       : {'idBank' : 0}
            },
            {
                path        : 'cuentas/:idBank/editar',
                name        : ROUTE_BANK_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/banks/BankAdd.vue'),
                props       : true
            },
            {
                path        : `cuentas/:idBank/movimientos`,
                name        : ROUTE_BANK_HISTORY_MOVE_LIST,
                beforeEnter : auth,
                component   : () => import('../components/banks/BankMoveList.vue'),
                props       : true
            },

            //PROMOTIONS
            {
                path        : 'promociones',
                name        : ROUTE_PROMOTIONS,
                beforeEnter : auth,
                component   : () => import('../components/promotions/PromotionFolder.vue')
            },
            {
                path        : 'promociones/:idFolder/lista',
                name        : ROUTE_PROMOTION_FOLDER,
                beforeEnter : auth,
                component   : () => import('../components/promotions/PromotionList.vue'),
                props       : true
            },
            {
                path        : 'promociones/:idFolder/agregar',
                name        : ROUTE_PROMOTION_ADD,
                beforeEnter : auth,
                component   : () => import('../components/promotions/PromotionAdd.vue'),
                props       : true
            },
            {
                path        : 'promociones/:idFolder/:idPromotion/editar',
                name        : ROUTE_PROMOTION_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/promotions/PromotionAdd.vue'),
                props       : true
            },

            /**Profile Admin */
            {
                path        : `perfil`,
                name        : ROUTE_PROFILE,
                beforeEnter : auth,
                component   : () => import('../components/configurations/profile.vue'),
                props       : true
            },

            /**Configurations */
            {
                path        : `configuracion`,
                name        : ROUTE_CONFIGURATIONS,
                beforeEnter : auth,
                component   : () => import('../components/configurations/GeneralConfiguration.vue'),
                props       : true
            },
            
        ]
    }
]
 
const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes
})
 
export default router