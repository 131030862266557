<template>
    <div class="page-header">
        <div class="row">
            <div class="col-lg-10">
                <h3 class="page-title">{{ title }}</h3>
                <ul class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                    <li class="breadcrumb-item" v-for="bread in list_breadcrumb" :key="bread.id">
                        <router-link :to="bread.url"> {{ bread.name }} </router-link>
                    </li>
                    <li class="breadcrumb-item active">{{ title }}</li>
                </ul>
            </div>
            <div class="col-lg-2 text-end">
                <BackPage />
            </div>
        </div>
    </div>
</template>
<script>
import BackPage from './BackPage.vue';
export default {
    name: 'BreadcrumbPage',
    props:['title', 'list_breadcrumb'],
    components:{
        BackPage,
    }
}
</script>