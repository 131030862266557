import axios from "axios";

export default {
    namespaced: true,

    state: {
        userData: null,
        menuData: null,
        actionData: null,
        profileData: null,
    },

    getters: {
        user: state => state.userData,
        menu: state => state.menuData,
        profile: state => state.profileData,
        action: state => state.actionData
    },

    mutations: {
        setUserData(state, user) {
            state.userData = user == null ? null : user.user;
            state.menuData = user == null ? null :  user.menus;
            state.profileData = user == null ? null : user.profile;
            state.actionData = user == null ? null : user.actions;
        }
    },

    actions: {
        getUserData({ commit }) {
            axios
            .get(`/admin/user`)
            .then(response => {
                commit("setUserData", response.data);
            })
            .catch(() => {
                localStorage.removeItem("authToken");
            });
        },

        sendLoginRequest({ commit }, data) {
            commit("setErrors", {}, { root: true });
            return axios
            .post(`admin/login`, data)
            .then(response => {
                commit("setUserData", response.data);
                localStorage.setItem("authToken", response.data.data.access_token);
            }).catch(error =>{
                console.log(error);
            });
        },

        sendLogoutRequest({ commit }) {
            axios.post(`/admin/logout`).then(() => {
                commit("setUserData", null);
                localStorage.removeItem("authToken");
            });
        },
    }
};
