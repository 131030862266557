<template>
       <!-- Header -->
		<div class="header">
			<div class="header-left"> 
				<router-link to="/admin/dashboard" class="logo logo-small">
					<img :src="config.image" alt="Logo" width="30" height="30">
                </router-link>
			</div>
			<a href="javascript:void(0);" id="toggle_btn">
				<i class="fas fa-align-left"></i>
			</a>
			<a class="mobile_btn" id="mobile_btn" href="javascript:void(0);">
				<i class="fas fa-align-left"></i>
			</a>
			
			<ul class="nav user-menu">
				<!-- Notifications -->
				<li class="nav-item dropdown noti-dropdown">
					<a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
						<i class="far fa-bell"></i>  <span class="badge badge-pill" v-if="totalNotification > 0"></span>
					</a>
					<div class="dropdown-menu dropdown-menu-right notifications">
						<div class="topnav-dropdown-header">
							<span class="notification-title">Notificaciones ( {{totalNotification}} )</span>
							<a v-if="totalNotification > 0" class="clear-noti" @click="clearAllNotifications()"> Limpiar todo </a>
						</div>
						<div class="noti-content">
							<ul class="notification-list">
								<li class="notification-message" v-for="item in notifications" :key="'noti'+item.id" @click="getNotifications()">  
									<router-link :to="`${item.data.url}?notification=${item.id}`">
										<div class="media">
											<span class="avatar avatar-sm">
												<img class="avatar-img rounded-circle" alt="" :src="item.data.icon">
											</span>
											<div class="media-body">
												<p class="noti-details">
													<span class="noti-title"> {{ item.data.title }} </span>
												</p>
												<p class="noti-time">
													<span class="notification-time"> {{ item.data.date | date_format_time }} </span>
												</p>
											</div>
										</div>
									</router-link>
								</li>
							</ul>
						</div>
						<div class="topnav-dropdown-footer">
							<a class="clear-noti" @click="getNotifications()" >Actualizar</a>
						</div>
					</div>
				</li>
				<!-- /Notifications -->
				
				<!-- User Menu -->
				<li class="nav-item dropdown">
					<a href="javascript:void(0)" class="dropdown-toggle user-link  nav-link" data-toggle="dropdown">
						<span class="user-img">
							<img class="rounded-circle" src="@/assets/img/user.jpg" width="40" alt="Admin">
						</span>
					</a>
					<div class="dropdown-menu dropdown-menu-right">
						<router-link class="dropdown-item" to="/admin/perfil">Mi perfil</router-link>
						<router-link class="dropdown-item" to="/admin/configuracion">Configuración de sistema</router-link>
						<a class="dropdown-item" href="javascript:;" @click="logout()">Cerrar sesión</a>
					</div>
				</li>
				<!-- /User Menu -->
				
			</ul>
		</div>
		<!-- /Header -->
</template>
<script>
	import $ from 'jquery';
	import { mapActions } from "vuex";
	import { ROUTE_LOGIN } from "../../enums/RouteNameEnums";
	export default {
		name: 'TopBar',
		data(){
			return {
				config 				: {},
				notifications		: [],
				totalNotification	: 0,
			}
		},
		created(){
			this.getNotifications();

			window.Push.Permission.request();
            window.Echo.private(`App.Models.User.1`)
            .notification((notification) => {
                if(notification.icon){
                    window.Push.create(notification.title, {
                        body: notification.body,
                        link: notification.url,
                        icon: notification.icon,
                        onClick: function () {
                            window.location.href = notification.url;
                            this.close();
                        }
                    });
                }
                this.getNotifications();
            });
		},
		mounted() {

			setTimeout(() => {
				const config = JSON.parse(localStorage.getItem('configuration'));
				if (config !== undefined && config !== null) {
					this.config = config;
				}
			}, 2000);
			// Variables declarations
			
			var $wrapper = $('.main-wrapper');
			//var $pageWrapper = $('.page-wrapper');
			//var $slimScrolls = $('.slimscroll');
			
			// Sidebar
			
			var Sidemenu = function() {
				this.$menuItem = $('#sidebar-menu a');
			};
			
			function init() {
				var $this = Sidemenu;
				$('#sidebar-menu a').on('click', function(e) {
					if($(this).parent().hasClass('submenu')) {
						e.preventDefault();
					}
					if(!$(this).hasClass('subdrop')) {
						$('ul', $(this).parents('ul:first')).slideUp(350);
						$('a', $(this).parents('ul:first')).removeClass('subdrop');
						$(this).next('ul').slideDown(350);
						$(this).addClass('subdrop');
					} else if($(this).hasClass('subdrop')) {
						$(this).removeClass('subdrop');
						$(this).next('ul').slideUp(350);
					}
				});
				$('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');
				$this;
			}
			// Sidebar Initiate
			init();
		
			// Mobile menu sidebar overlay
			
			$('body').append('<div class="sidebar-overlay"></div>');
			$(document).on('click', '#mobile_btn', function() {
				$wrapper.addClass('slide-nav');
				$('.sidebar-overlay').toggleClass('opened');
				$('html').addClass('menu-opened');
				return false;
			});
			
			// Sidebar overlay
		
			$(".sidebar-overlay").on("click", function () {
				$wrapper.removeClass('slide-nav');
				$(".sidebar-overlay").removeClass("opened");
				$('html').removeClass('menu-opened');
			});	
		
		
			// Small Sidebar

			$(document).on('click', '#toggle_btn', function() {
				if($('body').hasClass('mini-sidebar')) {
					$('body').removeClass('mini-sidebar');
					$('.subdrop + ul').slideDown();
				} else {
					document.body.classList.add('mini-sidebar');
					$('body').addClass('mini-sidebar');
					$('.subdrop + ul').slideUp();
				}
				setTimeout(function(){ 
					//mA.redraw();
					//mL.redraw();
				}, 300);
				return false;
			});
		
			$(document).on('mouseover', function(e) {
				e.stopPropagation();
				if($('body').hasClass('mini-sidebar') && $('#toggle_btn').is(':visible')) {
					var targ = $(e.target).closest('.sidebar').length;
					if(targ) {
						$('body').addClass('expand-menu');
						$('.subdrop + ul').slideDown();
					} else {
						$('body').removeClass('expand-menu');
						$('.subdrop + ul').slideUp();
					}
					return false;
				}
				
				$(window).scroll(function() {
					if ($(window).scrollTop() >= 30) {
						$('.header').addClass('fixed-header');
					} else {
						$('.header').removeClass('fixed-header');
					}
				});
				
				$(document).on('click', '#loginSubmit', function() {
					$("#adminSignIn").submit();
				});
			});

			$('.table-responsive').on('show.bs.dropdown', function () {
                $('.table-responsive').css( "overflow", "inherit" );
            });

            $('.table-responsive').on('hide.bs.dropdown', function () {
                $('.table-responsive').css( "overflow", "auto" );
            });
		},
		methods:{
			...mapActions("auth", ["sendLogoutRequest"]),
			logout() {
				this.sendLogoutRequest();
				setTimeout(()=>{
					this.$router.push({ name: ROUTE_LOGIN });
				},1500);
				
			},
			getNotifications(){
                fetch(`${this.$baseUrl}/api/admin/notifications`,{
					headers: {
						'Authorization' : `Bearer ${localStorage.getItem("authToken")}`
					},
				})
                .then(response => response.json())
				.then(data => {
					this.notifications 		= data.data;
					this.totalNotification	= data.count;
				})
				.catch(error => {
					console.error('Error en la fetch:', error);
				});
            },
			clearAllNotifications(){
				this.$axios
				.post(`${this.$baseUrl}/api/admin/notifications-clear-all`,{
					'_method'           : 'PUT'
				})
				.then(response => {
					this.getNotifications();
				})
				.catch((e) => {
					this.$store.commit("setErrors", e);
				});
			},
		}
	}
</script>
<style>
.clear-noti{
	cursor: pointer;
}
</style>